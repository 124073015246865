import React from 'react';

import css from "./Membership.module.css";
import Button from '../Button/Button';

export const Membership = props => {


    return (
        <div className={css.banner}>
            <div className={css.bannerInner}>
                <div className={css.col1}>
                    <h4 className={css.projectName}>Master Injector</h4>
                    <h4 className={css.Membership}>

                        Memberships
                    </h4>
                </div>
                <div className={css.col2}>
                    <b>The Membership Includes:</b>
                    Medical Director, Mid-Level Providers For Exams, Client & Charting Platform, Access To Distributors And Pharmacy To Purchase Products
                </div>
                <div className={css.col3}>
                    <div className={css.priceInfo}>
                        <div>
                            <p className={css.label}>Injector</p>
                            <p className={css.price}>$250 Monthly</p>
                        </div>
                        <div>
                            <p className={css.label}>Nurse Injector</p>
                            <p className={css.price}>$375 Monthly</p>
                        </div>
                    </div>
                    <Button>Unlock After 1st Course</Button>
                </div>
            </div>
        </div>
    );
};


export default Membership;
